import ReactOnRails from 'react-on-rails';

import MobileNav from '@components/menus/MobileNav';
import PlansCarousel from '@components/carousels/Plans';
import SideMenuRouter from '@src/SideMenuRouter';
import WyskPopup from '@components/popups/WyskPopup';

ReactOnRails.register({
  MobileNav,
  PlansCarousel,
  SideMenuRouter,
  WyskPopup,
});
