import React, { useEffect, useRef, useState, useLayoutEffect, useCallback } from 'react';

import { style, stylesheet } from 'typestyle';
import classnames from 'classnames';
import { useClickAway } from "@uidotdev/usehooks";

const POPUP_Y_OFFSET = 10;
const POPUP_X_OFFSET = -20;
const POPUP_WIDTH = 300;
const POPUP_MAX_HEIGHT = 400;
const POPUP_MIN_HEIGHT = 100;
const POPUP_MIN_BOTTOM_MARGIN = 30;
const POPUP_MIN_LEFT_MARGIN = 30;
const POPUP_MIN_RENDER_TO_RIGHT_OF_TRIGGER = 10;
const HEADER_HEIGHT = 25;
const HEADER_Y_MARGIN = 5;

// This is copied from the "computed" tab in chrome dev tools, put here as a constant so that we don't have to calculate
// it.
const POPUP_CARET_WIDTH = 15.84;

// when we calculate the max height of the popup content, the result has to be (popup_height - header_height -
// (header_y_margin * 2) - 3px). I don't know where the 3px comes from.
const HEADER_UNKNOWN_HEIGHT_DIFERENCE = 3;
const MAX_CONTENT_HEIGHT_HEADER_DIFFERENCE = HEADER_HEIGHT + (HEADER_Y_MARGIN * 2) + HEADER_UNKNOWN_HEIGHT_DIFERENCE;

// When rendered, the caret has slightly more space on the right than the left. This is used to help center the caret
// properly.
const CARET_EXTRA_RIGHT_MARGIN = 3;

const defaultPopupPosition: PopupPosition = {
  top: 0,
  left: 0,
  maxHeight: POPUP_MAX_HEIGHT,
  width: POPUP_WIDTH,
  caretLeft: Math.abs(POPUP_X_OFFSET)
};

const css = stylesheet({
  'wysk-popup': {
    backgroundColor: 'white',
    border: '1px solid #e6e5e5',
    borderLeft: '1px solid #e1a913',
    borderRadius: 5,
    boxShadow: '0 6px 35px rgba(135, 135, 135, 0.24)',
    cursor: 'default',
    fontSize: '14px',
    left: '0',
    lineHeight: '22px',
    maxHeight: POPUP_MAX_HEIGHT,
    maxWidth: POPUP_WIDTH,
    minHeight: POPUP_MIN_HEIGHT,
    minWidth: POPUP_WIDTH,
    position: 'fixed',
    textAlign: 'left',
    top: '0',
    whiteSpace: 'normal',
    width: '100%',
    zIndex: 1000,

    $nest: {
      '&::after': {
        '-ms-transform': 'scaleX(2) rotate(0deg)',
        '-webkit-transform': 'scaleX(2) rotate(0deg)',
        color: 'transparent',
        content: "'▲'",
        display: 'block',
        fontSize: '8px',
        lineHeight: '22px',
        position: 'absolute',
        textShadow: '0 0 0 white, 1px 1.5px 0 white, -1px 1.5px 0 white, 1.5px 2.5px 0 white, -1.5px 2.5px 0 white, -1px 0 0 #e6e5e5, 0 -2px 0 #e6e5e5, 1px 0 0 #E6E5E6',
        top: '-14px',
        transform: 'scaleX(2) rotate(0deg)'
      }
    }
  },
  'wysk-popup-header': {
    color: '#21528d !important',
    cursor: 'inherit !important',
    fontSize: '14px !important',
    lineHeight: `${HEADER_HEIGHT}px !important`,
    margin: `${HEADER_Y_MARGIN}px 14px !important`,
    marginLeft: '14px !important',
    textTransform: 'none'
  },
  'wysk-popup-header-hr': {
    borderBottom: '1px solid #EFEFEF',
    width: '100%',
    margin: 0,
    border: 'none'
  },
  'wysk-popup-close-button': {
    background: 'transparent !important',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'max-content',
    padding: 0,
    position: 'absolute',
    right: 7,
    top: 6,
  },
  'wysk-popup-close-image': {
    padding: 8,
  },

  'wysk-popup-content': {
    color: '#7f7f7f',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto',
    padding: '7px 14px 14px 14px',
    '$nest': {
      'p': {
        fontSize: 15
      },
      'p:last-of-type': {
        marginBottom: 0
      }
    }
  },
});

interface WyskPopupContainerProps {
  header: string;
  content: string;
  selector: string;
}

interface WyskPopupProps {
  header: string;
  content: string;
  onClose: (event: React.MouseEvent) => void;
  triggerRef: React.MutableRefObject<HTMLElement | null>;
}

interface PopupPosition {
  caretLeft: number;
  left: number;
  maxHeight: number;
  top: number;
  width: number;
}

function calculatePopupPosition(triggerElement: HTMLElement | void): PopupPosition {
  if (!triggerElement) return defaultPopupPosition;

  const contentFrame = document.querySelector('.content-frame');
  if (!contentFrame) return defaultPopupPosition;

  const contentFrameRect = contentFrame.getBoundingClientRect();
  const { left: contentFrameLeft, width: contentFrameWidth } = contentFrameRect;

  const triggerRect = triggerElement.getBoundingClientRect();
  const { top: triggerTop, left: triggerLeft, width: triggerWidth, height: triggerHeight } = triggerRect;

  const position = {
    caretLeft: 0,
    left: triggerLeft + (triggerWidth / 2) + POPUP_X_OFFSET,
    maxHeight: POPUP_MAX_HEIGHT,
    top: triggerTop + triggerHeight + POPUP_Y_OFFSET,
    width: POPUP_WIDTH
  };

  // if the popup would be off the screen, make it shorter so that it is POPUP_MIN_BOTTOM_MARGIN from the bottom of the
  // screen
  if (position.top + position.maxHeight > window.innerHeight) {
    position.maxHeight = window.innerHeight - position.top - POPUP_MIN_BOTTOM_MARGIN;

    if (position.maxHeight < POPUP_MIN_HEIGHT) {
      position.maxHeight = POPUP_MIN_HEIGHT;
    }
  }

  // if the popup would be off the right side of the content frame, move it left so that there is POPUP_MIN_LEFT_MARGIN
  // of space between the popup and the right side of the content frame
  if (position.left + position.width > contentFrameLeft + contentFrameWidth) {
    position.left = contentFrameLeft + contentFrameWidth - position.width - POPUP_MIN_LEFT_MARGIN;
  }

  // if the right side of the popup is less then POPUP_MIN_RENDER_TO_RIGHT_OF_TRIGGER past the right side of the
  // trigger, move it right so that it is POPUP_MIN_RENDER_TO_RIGHT_OF_TRIGGER past the right side of the trigger
  if (position.left + position.width < triggerLeft + triggerWidth + POPUP_MIN_RENDER_TO_RIGHT_OF_TRIGGER) {
    position.left = triggerLeft + triggerWidth + POPUP_MIN_RENDER_TO_RIGHT_OF_TRIGGER - position.width;
  }

  position.caretLeft = triggerLeft + (triggerWidth / 2) - (POPUP_CARET_WIDTH / 2) + CARET_EXTRA_RIGHT_MARGIN - position.left;

  return position;
}

export default function WyskPopupContainer({ header, content, selector }: WyskPopupContainerProps) {
  const [show, setShow] = useState(false);
  const triggerRef = useRef<HTMLElement>(null);

  const closePopup = (event: React.MouseEvent) => {
    if (event.target === triggerRef.current) return;

    setShow(false);
  }

  useEffect(() => {
    triggerRef.current = document.getElementById(selector);

    const showPopup = () => setShow(true);
    const triggerRefElement = triggerRef.current;

    if (triggerRefElement) {
      triggerRefElement.addEventListener('mouseup', showPopup);
    }

    return () => {
      if (!triggerRefElement) return;

      triggerRefElement.removeEventListener('mouseup', showPopup);
    }
  }, [selector]);

  if (!show) return null;

  return (
    <WyskPopup header={header} content={content} onClose={closePopup} triggerRef={triggerRef} />
  );
}

function WyskPopup({ header, content, onClose, triggerRef }: WyskPopupProps) {
  const popupRef = useClickAway<HTMLDivElement>(onClose);
  const [popupPosition, setPopupPosition] = useState<PopupPosition>(() => calculatePopupPosition(triggerRef.current));

  const updatePopupPosition = useCallback(() => {
    if (triggerRef.current) {
      setPopupPosition(calculatePopupPosition(triggerRef.current));
    }
  }, [triggerRef]);

  useLayoutEffect(() => {
    updatePopupPosition();

    window.addEventListener('resize', updatePopupPosition);
    window.addEventListener('scroll', updatePopupPosition);

    return () => {
      window.removeEventListener('resize', updatePopupPosition);
      window.removeEventListener('scroll', updatePopupPosition);
    }
  }, [updatePopupPosition]);

  return (
    <div
      className={classnames(
        css['wysk-popup'],
        style({
          $nest: {
            '&::after': {
              left: popupPosition.caretLeft
            }
          }
        })
      )}
      ref={popupRef}
      style={popupPosition}
    >
      <h3 className={css['wysk-popup-header']}>{header}</h3>
      <button className={css['wysk-popup-close-button']} tabIndex={0} onClick={(e) => onClose(e)}>
        <img src="/images/close_popup_x.svg"
          className={css['wysk-popup-close-image']}
          alt="Close popup"
          title="Close popup"
        />
      </button>
      <hr className={css['wysk-popup-header-hr']} />
      <div
        className={css['wysk-popup-content']}
        style={{ maxHeight: popupPosition.maxHeight - MAX_CONTENT_HEIGHT_HEADER_DIFFERENCE }}
      >{
          content.split(/\r?\n\r?\n/).map(
            (paragraph, index) => (<p key={index}>{paragraph}</p>)
          )
        }
      </div>
    </div >
  )
}
